.videogallerymain {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 120px auto;
  gap: 2vw;
}

.subVideogallery {
  width: 70%;
  display: flex;
  flex-direction: column;
  gap: 2vw;
}

.videogallerytext {
  background: url(../../assest/Gallery/Video-Gallery-text.svg) no-repeat;
  width: clamp(250px, 10vw, 470px) !important;
  height: 5vh;
  background-size: contain;
  background-position: center;
  display: flex;
  align-items: center;
  gap: 10px;
}

.videogallerytext h5 {
  font-weight: 600;
  font-size: clamp(20px, 10vw, 20px);
  color: #0984ff;
  font-family: "Roboto", sans-serif;
  text-transform: uppercase;
}

.videostyle {
  width: 65%;
  height: 384px;
}

@media screen and (max-width: 992px) {
  .videogallerymain {
    width: 100%;
    gap: 20px;
    margin: 60px auto;
  }

  .subVideogallery {
    width: 90%;
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  .videostyle {
    width: 100%;
    height: 384px;
  }

  .videogallerytext {
    padding: 10px;
  }
}
