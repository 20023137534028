.deleteformmain {
  width: 100%;
  background: linear-gradient(105.43deg, #f2f9ff 39.18%, #c9e4ff 93.15%) top /
      100% 30% no-repeat,
    #ffffff;

  border-radius: 55px;
  padding: 20px;
}

.deleteformsub {
  width: 90%;
  display: flex;
  flex-direction: column;
  margin: auto;
}

.deletetext {
  display: flex;
  flex-direction: column;
  gap: 15px;
  justify-content: center;
  margin-top: 20px;
}

.deletetext h1 {
  font-weight: 500;
  font-size: 1.25vw;
  color: black;
  font-family: "Roboto", sans-serif;
}

.formicdata {
  margin-top: 110px;
}

.inputdata {
  width: 100%;
  border: none;
  outline: none;
  border-bottom: 1px solid #bababa;
  margin: 20px 0px;
}

.textareadata {
  width: 100%;
  border: none;
  outline: none;
  border-bottom: 1px solid #bababa;
  margin: 20px 0px;
}

.labeldata {
  font-weight: 500;
  font-size: 0.83vw;
  color: #9c9c9c;
  font-family: "Roboto", sans-serif;
}

@media screen and (max-width: 992px) {
  .deleteformmain {
    width: 100%;
    background: linear-gradient(105.43deg, #f2f9ff 39.18%, #c9e4ff 93.15%) top /
        100% 20% no-repeat,
      #ffffff;
  }

  .deleteformsub {
    width: 90%;
    display: flex;
    flex-direction: column;
    margin: auto;
  }

  .deletetext {
    display: flex;
    flex-direction: column;
    gap: 15px;
    justify-content: center;
    margin-top: 20px;
  }

  .deletetext h1 {
    font-size: clamp(20px, 2vw, 22px);
  }

  .formicdata {
    margin-top: 50px;
  }

  .labeldata {
    font-size: clamp(16px, 2vw, 20px);
  }
}
