.aboutMain {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 6.25vw auto;
  gap: 2vw;
}

.imgsec11 img {
  width: 30vw;
}

.aboutTextSec {
  display: flex;
  flex-direction: column;
  gap: 1vw;
  width: 30%;
}

.abouttext12 {
  background: url(../../assest/Home/AboutUSText.svg) no-repeat;
  width: clamp(280px, 15vw, 336px);
  height: 5vh;
  background-size: contain;
  background-position: center;
  display: flex;
  align-items: center;
  gap: 10px;
}

.abouttext h5 {
  font-weight: 600;
  font-size: 1.042vw;
  color: #000;
}

.aboutTextSec p {
  font-weight: 600;
  font-size: 0.729vw;
  color: #9c9c9c;
  line-height: 20px;
}

.aboutTextSec h1 {
  font-weight: 900;
  font-size: 2.083vw;
  color: #2774c1;
  text-transform: uppercase;
  font-style: italic;
}

/* .aboutTextSec span {
  color: #2774c1;
} */

@media screen and (max-width: 992px) {
  .aboutMain {
    width: 100%;
    display: flex;
    flex-direction: column-reverse;
  }

  .imgsec11 img {
    width: 100%;
    height: auto;
    padding: 20px;
  }

  .aboutTextSec {
    width: 85%;
    gap: 15px;
  }

  .aboutTextSec h1 {
    font-size: 40px;
  }

  .abouttext h5 {
    font-size: 20px;
  }

  .aboutTextSec p {
    font-size: 14px;
  }
}
