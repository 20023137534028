footer {
  background-color: #051424;
  width: 100%;
  height: 42vh;
  /* height: 456px; */
  display: flex;
  justify-content: center;
  /* padding-top: 40px; */
}

.footermain {
  width: 85%;
  /* height: 456px; */
  height: 42vh;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.footersec1 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1.5vw;
  height: 40vh;
}

.store12 {
  width: 8.56vw;
}

.logoicon {
  width: 100%;
}

.socialmedia {
  display: flex;
  gap: 15px;
}

.contactpart {
  display: flex;
  gap: 10px;
}

.contactpart img {
  width: 2.188vw;
}

.contactpart p {
  font-weight: 500;
  /* font-size: clamp(12px, 2vw, 14px); */
  font-size: 0.729vw;
  color: #56aaff;
  font-family: "Roboto", sans-serif;
  margin-bottom: 0.3vw;
}

.contactpart h1 {
  font-weight: 500;
  /* font-size: clamp(18px, 2vw, 20px); */
  font-size: 1.042vw;
  color: #fff;
  font-family: "Roboto", sans-serif;
}

/* .socialmedia img {
  width: 2.188vw;
} */

.circleiconbg {
  border-radius: 50%;
  border: 1px solid #56aaff;
  width: 2.5vw;
  height: 4.8889vh;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  background-color: black;
  color: #2774c1;
  font-size: 1vw;
}

.circleiconbg:hover {
  background-color: #56aaff;
  color: white;
}

.footersec1 h2 {
  font-weight: 500;
  font-size: 0.833vw;
  /* font-size: clamp(16px, 2vw, 16px); */
  color: #fff;
  font-family: "Roboto", sans-serif;
  margin-bottom: 10px;
}

ul {
  list-style-type: none;
}

.footersec1 li a {
  text-decoration: none;
  font-weight: 700;
  /* font-size: clamp(16px, 2vw, 18px); */
  font-size: 0.833vw;
  color: #fff;
  font-family: "Roboto", sans-serif;
}

.footersec1 li a:hover {
  color: #56aaff;
  transition: all 0.3s linear;
}

.emilsec {
  width: 19vw;
  /* height: 45px; */
  background-color: #fff;
  padding: 10px;
  border-radius: 5px;
}

.emilsec input {
  width: 100%;
  border: none;
  outline: none;
}

.footersec1 label {
  font-weight: 600;
  /* font-size: clamp(16px, 2vw, 20px); */
  font-size: 1.042vw;
  color: #fff;
  font-family: "Roboto", sans-serif;
}

.footersec1 h4 {
  font-weight: 500;
  /* font-size: clamp(12px, 2vw, 14px); */
  font-size: 0.833vw;
  color: #9c9c9c;
  font-family: "Roboto", sans-serif;
}

.footersec1 button {
  background-color: #2774c1;
  /* width: 177px; */
  width: 9.219vw;
  /* height: 44px; */
  height: 4.1vh;
  color: white;
  border-radius: 55px;
  border: 1px solid #2774c1;
}

.footersec1 button:hover {
  background-color: #fff;
  color: #2774c1;
  transition: all 0.3s linear;
}

.delete {
  font-weight: 500;
  /* font-size: clamp(12px, 2vw, 14px); */
  font-size: 0.729vw;
  color: white;
  font-family: "Roboto", sans-serif;
  margin-top: 20px;
  margin-left: 10px;
}

.deleteclc {
  color: #0984ff;
  text-decoration: underline;
  cursor: pointer;
}

.error-message {
  color: red;
}

.stores1 {
  display: flex;
  gap: 10px;
}

.copy {
  width: 100%;
  height: 47px;
  background: #000;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: auto;
}

.copy p {
  font-weight: 500;
  /* font-size: clamp(12px, 2vw, 14px); */
  font-size: 0.729vw;
  color: #9c9c9c;
  font-family: "Roboto", sans-serif;
}

.copy h1 {
  font-weight: 500;
  /* font-size: clamp(12px, 2vw, 14px); */
  font-size: 0.729vw;
  color: #9c9c9c;
  font-family: "Roboto", sans-serif;
}

@media screen and (max-width: 992px) {
  .copy {
    height: auto;
    flex-direction: column;
    padding: 15px 0;
    gap: 20px;
  }

  .copy h1 {
    font-size: clamp(12px, 2vw, 14px);
  }

  .copy p {
    font-size: clamp(12px, 2vw, 14px);
  }

  footer {
    height: auto;
  }

  .footermain {
    width: 100%;
    height: auto;
    flex-direction: column;
    justify-content: start;
    align-items: start;
    gap: 30px;
    padding: 100px 0px 30px 30px;
  }

  .footersec1 {
    display: flex;
    gap: 40px;
    height: auto;
  }

  .store12 {
    width: 123px;
  }

  .footersec1 button {
    width: 177px;
    height: 44px;
  }

  .footersec1 label {
    font-size: clamp(16px, 2vw, 20px);
  }

  .delete {
    font-size: clamp(14px, 2vw, 16px);
    margin-left: 0px;
  }

  .footersec1 h4 {
    font-size: clamp(12px, 2vw, 14px);
  }

  .contactpart img {
    width: 43px;
  }

  .contactpart p {
    font-size: clamp(12px, 2vw, 14px);
  }

  .contactpart h1 {
    font-size: clamp(18px, 2vw, 20px);
  }

  .circleiconbg {
    width: 42px;
    height: 42px;
    font-size: 19px;
  }

  /* .socialmedia img {
    width: 42px;
  } */

  .footersec1 h2 {
    font-size: clamp(16px, 2vw, 16px);
  }

  .footersec1 li a {
    font-size: clamp(16px, 2vw, 18px);
  }

  .emilsec {
    width: 264px;
    /* height: 45px; */
    background-color: #fff;
    padding: 10px;
    border-radius: 5px;
  }
}
