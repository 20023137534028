.AddOnMain1 {
  background: url(../../assest/Services/Service2.svg) no-repeat;
  width: 100%;
  background-position: center;
  background-size: contain;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: 5.5vw 0;
}

.addOnsection {
  width: 30%;
}

.addOnsection img {
  margin-bottom: 8vw;
  margin-left: 2vw;
  width: 100%;
}

.servicebtn1 {
  position: absolute;
  top: 80%;
  left: 7vw;
}

.addOntext1 {
  width: 60%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 0.6vw;
  padding-top: 9vw;
  margin-left: 8vw;
}

.addOntext1 h3 {
  width: 45%;
  font-weight: 900;
  font-size: 2.083vw;
  color: #2774c1 !important;
  text-transform: uppercase;
  margin-bottom: 0.8vw;
}

/* .addOntext1 span {
  color: #2774c1;
} */

.addOntext1 h4 {
  font-weight: 800;
  font-size: 1.042vw;
  color: #292929;
}

.addOntext1 h5 {
  width: 92%;
  font-weight: 500;
  /* font-size: 1.042vw; */
  font-size: 0.833vw;
  color: #292929;
  font-family: "Roboto", sans-serif;
  line-height: 1.25vw;
  margin-top: 0.2vw;
}

.addOntext1 h3 {
  width: 45%;
  font-weight: 900;
  font-size: 2.083vw;
  color: #020202;
  font-family: "Roboto", sans-serif;
  margin-bottom: 0.8vw;
}

.servicetext4 {
  background: url(../../assest/Services/Services5.svg) no-repeat;
  width: 15vw;
  height: 4vh;
  background-size: contain;
  background-position: center;
  display: flex;
  align-items: center;
  gap: 10px;
  position: relative;
  top: 8vw;
  left: 30vw;
}

.servicetext4 h5 {
  font-weight: 600;
  font-size: 1.042vw;
  color: #000;
  font-family: "Roboto", sans-serif;
}

@media screen and (max-width: 992px) {
  .AddOnMain1 {
    background: none;
    display: flex;
    flex-direction: column-reverse;
    gap: 25px;
    top: 0;
    left: 0;
  }
  .addOnsection {
    width: 100%;
  }
  .servicetext4 h5 {
    font-size: 18px;
  }

  .addOntext1 h3 {
    width: 100%;
    font-size: 34px;
    margin-bottom: 12px;
  }

  .addOntext1 {
    width: 100%;
    gap: 15px;
    padding-top: 0px;
    margin-left: 0px;
  }

  .addOntext1 h4 {
    font-size: 18px;
  }

  .addOntext1 h5 {
    font-size: 16px;
    line-height: 21px;
  }

  .servicetext4 {
    width: clamp(250px, 10vw, 280px);
    top: 0;
    left: -36%;
    margin-top: 40px;
  }

  .servicebtn1 {
    position: absolute;
    top: 95%;
    left: 1%;
  }
}

@media screen and (max-width: 499px) {
  .servicebtn1 {
    position: absolute;
    top: 99%;
  }

  .servicetext4 {
    top: 0;
    left: -15%;
    margin-top: 40px;
  }
}
