.servicetypemain {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  /* margin: 120px auto; */
}

.subServicetype {
  width: 75%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 3.5vw;
  margin: auto auto 100px;
}

.servicetypetext {
  width: 90%;
  display: flex;
  flex-direction: column;
  gap: 1vw;
}

.servicetypetext h1 {
  font-weight: 900;
  font-size: 2.083vw;
  color: #2774c1;
  font-family: "Roboto", sans-serif;
  text-transform: uppercase;
  font-style: italic;
}

.servicetypetext span {
  color: #000;
}

.servicetypetext p {
  font-weight: 500;
  font-size: 0.833vw;
  color: #9c9c9c;
  font-family: "Roboto", sans-serif;
  line-height: 20px;
}

.servicetype {
  background-color: #e2e2e2;
  width: 284px;
  height: 64px;
  border-radius: 40px;
  padding: 15px;
}

.servicetype button {
  background-color: transparent;
  width: 116px;
  height: 41px;
  border-radius: 25px;
  border: none;
  outline: none;
  cursor: pointer;
  font-weight: 600;
  font-size: 0.938vw;
  color: #bababa;
}

.servicetype button.active {
  background-color: #ffffff;
  color: #2774c1;
  font-weight: 900;
  font-size: 1.1vw;
}

@media screen and (max-width: 992px) {
  .servicetypemain {
    width: 100%;
  }

  .subServicetype {
    width: 90%;
    gap: 50px;
  }

  .servicetypetext {
    gap: 16px;
  }

  .servicetypetext h1 {
    font-size: 30px;
  }

  .servicetypetext p {
    font-size: 14px;
  }

  .servicetype button {
    font-size: 18px;
  }

  .servicetype button.active {
    font-size: 20px;
  }
}
