.modal {
  display: none;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  border: 1px solid;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 999;
  overflow-y: scroll;
  scroll-behavior: smooth;

  &::-webkit-scrollbar {
    display: none;
  }
}

.modal-content {
  border-radius: 12%;
  /* height: 75vh; */
  margin: 10vw auto auto;
  background-color: #fff;
  width: 55%;
  gap: 10px;
  position: relative;
  padding-bottom: 1vw;
}

.show {
  display: block;
}

.hide {
  display: none;
}

.close {
  position: absolute;
  right: 1.5%;
  top: 2%;
  width: 1.5vw;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  color: var(--Primary, #8cc63f);
  cursor: pointer;
}

@media screen and (max-width: 992px) {
  .modal {
    height: 100vh;
    overflow-y: scroll;
    scroll-behavior: smooth;

    &::-webkit-scrollbar {
      display: none;
    }
  }
  .modal-content {
    height: auto;
    width: 90%;
    border-radius: 60px;
    margin-top: 25%;
  }

  .close {
    position: absolute;
    right: 1%;
    top: 2%;
    width: 35px;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    color: var(--Primary, #8cc63f);
    cursor: pointer;
  }
}

/* @media screen and (max-width: 400px) {
  .modal-content {
    height: auto;
    width: 90%;
    border-radius: 30px;
    padding-bottom: 120px;
  }
} */
