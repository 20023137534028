.servicemain1 {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.mainservice1 {
  max-width: 75%;
  margin: 60px auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.servicetext1 {
  width: 95%;
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin: 40px auto auto;
}

.servicetext1 h1 {
  width: 50%;
  font-weight: 900;
  font-size: clamp(30px, 5vw, 40px);
  color: #2774c1;
  font-family: "Roboto", sans-serif;
  text-transform: uppercase;
  font-style: italic;
}

/* .servicetext1 span {
  color: #000;
} */

.servicetext1 p {
  width: 85%;
  font-weight: 500;
  /* font-size: clamp(12px, 5vw, 16px); */
  font-size: 0.833vw;
  color: #9c9c9c;
  font-family: "Roboto", sans-serif;
}

@media screen and (max-width: 992px) {
  .servicemain1 {
    width: 100%;
  }

  .mainservice1 {
    max-width: 90%;
  }

  .servicetext1 h1 {
    width: 100%;
  }

  .servicetext1 {
    display: flex;
    flex-direction: column;
    gap: 15px;
    margin: auto;
  }

  .servicetext1 p {
    width: 100%;
    font-size: clamp(12px, 5vw, 16px);
  }
}
