@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Roboto", sans-serif;
}

.iconcircleBlack {
  font-size: 10px;
  color: #000;
  margin-top: 4px;
  margin-left: 15px;
}

.iconcircleBlue {
  font-size: 10px;
  color: #0984ff;
  margin-top: 4px;
  margin-left: 35px;
}

.iconcircleWhite {
  font-size: 10px;
  color: #fff;
  margin-top: 4px;
  margin-left: 15px;
}

/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
} */
