.monthlymain12 {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
}

.monthlymain {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 25px;
  margin: auto;
}

.monthlybox {
  width: 90%;
  height: 100%;
  border-radius: 60px;
  background-color: #fff;
  box-shadow: 4px 4px 55px 0px #0000000d;
  position: relative;
  margin: auto auto 50px auto;
}

.img22 {
  width: 100%;
}

.monthlytext {
  display: flex;
  flex-direction: column;
  gap: 1vw;
  margin-left: 14px;
  margin-top: 1vw;
}

.monthlymain h2 {
  width: 75%;
  position: absolute;
  top: 14%;
  left: 8%;
  font-weight: 600;
  font-size: 1.3vw;
  color: #fff;
  font-family: "Roboto", sans-serif;
  text-transform: uppercase;
}

.monthlymain h6 {
  width: 75%;
  position: absolute;
  top: 5%;
  left: 8%;
  font-weight: 700;
  font-size: 1.563vw;
  color: #fff;
  font-family: "Roboto", sans-serif;
  text-transform: uppercase;
}

.monthlymain h3 {
  font-weight: 600;
  font-size: 1vw;
  color: #9c9c9c;
  font-family: "Roboto", sans-serif;
}

.monthlymain span {
  font-weight: 600;
  font-size: 3.385vw;
  color: #000000;
  font-family: "Roboto", sans-serif;
}

.monthlymain h4 {
  font-weight: 600;
  font-size: 1vw;
  color: #9c9c9c;
  font-family: "Roboto", sans-serif;
}

.btn4 {
  width: 131px;
  height: 41px;
  border-radius: 55px;
  border: 1px solid #2774c1;
  cursor: pointer;
  color: #fff;
  font-weight: 900;
  font-size: clamp(16px, 5vw, 16px);
  font-family: "Roboto", sans-serif;
  background-color: #2774c1;
}

.yearlybox {
  width: 100%;
  height: 100%;
  border-radius: 60px;
  background-color: #fff;
  box-shadow: 4px 4px 55px 0px #0000000d;
  position: relative;
  margin: 50px auto auto auto;
}

.yearlybox:hover,
.monthlybox:hover {
  border-bottom: 1px solid #2774c1;
  border-left: 1px solid #2774c1;
  border-right: 1px solid #2774c1;
}

.btn5 {
  width: 12vw;
  height: 5vh;
  border-radius: 55px;
  border: 1px solid #2774c1;
  cursor: pointer;
  color: #fff;
  font-weight: 900;
  font-size: clamp(16px, 5vw, 16px);
  font-family: "Roboto", sans-serif;
  background-color: #2774c1;
  margin: auto;
}

@media screen and (max-width: 992px) {
  .monthlymain12 {
    width: 100%;
  }

  .monthlymain {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 30px;
  }

  .yearlybox:hover,
  .monthlybox:hover {
    border-bottom: none;
    border-left: none;
    border-right: none;
  }

  .monthlymain h2 {
    font-size: 20px;
  }

  .monthlymain h3 {
    font-size: 16px;
  }

  .monthlymain span {
    font-size: 65px;
  }

  .monthlymain h4 {
    font-size: 16px;
  }

  .btn5 {
    width: 228px;
    height: 52px;
  }
}

@media screen and (max-width: 499px) {
  .monthlymain {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 30px;
  }
}
