.contactmain {
  width: 100%;
  background: linear-gradient(105.43deg, #f2f9ff 39.18%, #c9e4ff 93.15%) top /
      100% 50% no-repeat,
    #ffffff;

  /* height: 20vh; */
  display: flex;
  flex-direction: column;
  border-radius: 55px;
  padding: 5px;
}

.contactmain h1 {
  font-weight: 900;
  font-size: 1.927vw;
  color: #2774c1;
  font-family: "Roboto", sans-serif;
  text-align: center;
  margin-top: 1.5vw;
}

.contactmain p {
  width: 80%;
  font-weight: 500;
  font-size: 1vw;
  color: #bababa;
  font-family: "Roboto", sans-serif;
  text-align: center;
}

.formcontact1 {
  gap: 1.5vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
}

.contactform {
  background-color: white;
  width: 90%;
  border-radius: 2.865vw;
  box-shadow: 4px 4px 55px 0px #0000000d;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1vw;
  padding: 1vw;
}

@media screen and (max-width: 992px) {
  .contactmain {
    width: 100%;
    border-radius: none;
    background: linear-gradient(105.43deg, #f2f9ff 39.18%, #c9e4ff 93.15%) top /
        100% 20% no-repeat,
      #ffffff;
  }
  .contactform {
    width: 100%;
    height: auto;
    border-radius: 30px;
    flex-direction: column-reverse;
    margin: auto;
    gap: 12px;
    padding: 12px;
  }

  .contactmain h1 {
    font-size: 30px;
  }

  .contactmain p {
    width: 100%;
    font-size: 16px;
  }

  .formcontact1 {
    width: 100%;
    height: auto;
  }
}
/* 
@media screen and (max-width: 499px) {
  .formcontact1 {
    margin-top: 50px;
  }
} */
