.gallerymain {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 12.5vw auto 100px;
  gap: 2vw;
}

.subgallery {
  width: 70%;
  display: flex;
  flex-direction: column;
  margin: auto;
  gap: 2vw;
}

.gallerycontent {
  display: flex;
  flex-direction: column;
  gap: 1vw;
}

.gallerytext {
  background: url(../../assest/Gallery/GalleryText.svg) no-repeat;
  width: clamp(200px, 15vw, 426px) !important;
  height: 5vh;
  background-size: contain;
  background-position: center;
  display: flex;
  align-items: center;
  gap: 10px;
}

.gallerytext h5 {
  font-weight: 600;
  font-size: clamp(20px, 5vw, 20px);
  /* font-size: 1.042vw; */
  color: #0984ff;
  font-family: "Roboto", sans-serif;
  text-transform: uppercase;
}

.gallerycontent h1 {
  width: 55%;
  font-weight: 800;
  font-size: clamp(30px, 5vw, 40px);
  /* font-size: 2.083vw; */
  color: #2774c1;
  font-family: "Roboto", sans-serif;
  text-transform: uppercase;
  line-height: 46px;
  font-style: italic;
}

.gallerycontent span {
  color: #000;
}

.gallerycontent p {
  width: 90%;
  font-weight: 600;
  font-size: clamp(14px, 5vw, 14px);
  color: #9c9c9c;
  font-family: "Roboto", sans-serif;
  line-height: 21px;
}

.china {
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.china1 {
  display: flex;
  gap: 10px;
}

.china img {
  width: 100%;
}

.china2 {
  display: flex;
  gap: 10px;
}

@media screen and (max-width: 768px) {
  .gallerymain {
    width: 95%;
    margin: 60px auto;
    gap: 20px;
  }

  .subgallery {
    width: 95%;
    gap: 20px;
  }

  .gallerycontent {
    width: 90%;
    gap: 10px;
  }

  .china {
    gap: 25px;
  }

  .china1 {
    display: flex;
    flex-direction: column;
    height: auto;
    gap: 25px;
  }

  .china img {
    width: 90%;
  }

  .china2 {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .gallerycontent h1 {
    width: 90%;
  }

  .gallerytext {
    width: 100%;
    gap: 10px;
    margin-left: 30px;
  }
}

@media screen and (max-width: 499px) {
  .china1 {
    gap: 10px;
  }

  .china {
    gap: 15px;
  }
}
