.navbar {
  width: 100%;
  height: 10.2vh;
  z-index: 999;
  position: fixed;
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.navbar.about {
  margin-top: 20px;
  height: auto;
  border: 1px solid #0b9573;
  z-index: 999;
}

:is(.navbar.about, .navbar.contact) > nav > div > div > .menu > .dropDown > ul {
  z-index: 999 !important;
  background-color: #fff;
  transition: all 0.3s linear;
}
.navbar > nav {
  height: 10.2vh;
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: #2774c1 !important;
  border: none;
}

.navbar > nav > div {
  display: flex;
}
.navbar > nav > a {
  margin: 5px 15px;
}
.navbar > nav > a > img {
  /* width: 190px;
  height: 34px; */
  width: 100%;
  cursor: pointer;
  object-fit: cover;
}
.navbar > nav > div > div {
  display: flex;
}

.navbar > nav > div > div > ul {
  display: flex;
  align-items: center;
  list-style-type: none;
}

.navbar > nav > div > div > ul > li {
  padding: 0 20px;
  margin: 0 10px;
  display: flex;
  align-items: center;
}

.navbar > nav > div > div > ul > li > div {
  transition: all 0.7s ease;
}

.menu,
ul > li > div > a {
  color: #fff;
  /* font-size: clamp(16px, 2vw, 18px); */
  font-size: 0.938vw;
  font-weight: 800;
  /* line-height: 21.09px; */
  text-decoration: none;
}

.navbar > nav > div > div > ul > li > div > .active {
  color: #0984ff;
  width: 6.458vw;
  height: 4vh;
  border-radius: 50px;
  border: 1px solid #56aaff;
  background: #fff;
  padding: 0.5vw 1.25vw;
  /* padding: 12px 25px; */
}

.menu > .link {
  position: relative;
  padding: 25px 10px;
  min-height: 110px;
  max-height: auto;
  display: flex;
}

.link > div {
  cursor: pointer;
  display: flex;
  align-items: center;
}

.link > div > div {
  transition: all 0.7s ease;
}

nav > div > div > ul {
  margin: 10px 0;
}

.contact {
  /* width: 124px; */
  /* height: 43px; */
  /* width: 6.042vw;
  height: 4vh; */
  border-radius: 55px;
  padding: 0.5vw 1vw;
  font-size: 0.938vw;
  font-weight: 800;
  /* border: 4px solid red; */
  border: 1px solid #56aaff;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background-color: transparent;
  color: #fff;
}

.bars {
  padding: 10px;
  display: none;
  margin-left: 30px;
  font-size: 34px;
  cursor: pointer;
  color: rgba(0, 0, 0, 0.35);
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.15);
  border-radius: 7px;
}

@media screen and (max-width: 1350px) {
  .navbar > nav > div > div > ul > li {
    padding: 0 10px;
    margin: 0 5px;
  }
}

@media screen and (max-width: 992px) {
  .navbar {
    max-width: 100%;
    min-width: auto;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1 !important;
    border: none;
    border-radius: 0;
  }

  @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
    .navbar {
      -webkit-backdrop-filter: blur(10px);
      backdrop-filter: blur(10px);
    }
  }

  @supports not ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
    .navbar {
      background-color: rgba(255, 255, 255, 0.8);
    }
  }
  .navbar > nav {
    justify-content: space-between;
    width: 100%;
    padding: 0px 20px !important;
  }
  .navbar > nav > a > img {
    /* width: 170px;
    height: 50px; */
    object-fit: cover;
  }
  .navbar > nav > div {
    width: 100%;
    position: absolute;
    top: -600px;
    left: 0;
    transition: all 3s ease;
    padding: 0px 20px;
    margin: auto;
    transition: all 0.4s ease;
  }
  .navbar > nav > div > div {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 20px 0;
  }

  .navbar > nav:has(.menu2) > div > .mobile {
    margin-top: 30px;
    max-height: 80vh;
    background-color: #2774c1;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;

    overflow-y: scroll;
    scroll-behavior: smooth;

    &::-webkit-scrollbar {
      display: none;
    }
  }
  .navbar > nav:has:not(.menu2) > div {
    transition: all 0.4s ease;
    margin: auto;
  }

  .navbar > nav > div > div > ul {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-right: none;
    margin: 0px 0px;
  }
  .navbar > nav > div > div > ul > li {
    padding: 10px 0;
    margin: 10px 0;
  }
  .menu2 > div {
    min-width: 100%;
  }
  .navbar > nav:has(.menu2) > div {
    width: 100%;
    position: absolute;
    top: 62px;
    padding: 0px 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    transition: all 0.4s ease;
  }
  .navbar > nav:has(.menu2) > div > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px 0;
  }
  .navbar > nav:has(.menu2) > div > div > .menu {
    padding: 0 60px;
    width: 100%;
  }
  .navbar > nav:has(.menu2) > div > div > .menu > .link {
    padding: 0;
    width: 100%;
    border-bottom: 1px solid #56aaff;
  }

  .navbar > nav:has(.menu2) > div > div > .menu > .link > ul {
    padding: 10px 0;
    transition: all 0.4s ease;
    list-style: none;
  }

  .navbar > nav > div > div > .menu > .link > ul > li {
    padding: 10px 0;
  }
  .navbar > nav:has(.menu2) > div > div > ul {
    width: 100%;
    padding: 0 60px;
    text-decoration: none;
  }
  .navbar > nav:has(.menu2) > div > div > ul > li {
    width: 100% !important;
    margin: 5px 0;
    padding: 10px 0;
  }
  .navbar > nav:has(.menu2) > div > div > ul > li:nth-child(1) {
    border-bottom: 1px solid #56aaff;
    border-radius: 0;
  }
  .navbar > nav:has(.menu2) > div > div > ul > li:nth-child(2) {
    border-bottom: 1px solid #56aaff;
    border-radius: 0;
  }
  .navbar > nav:has(.menu2) > div > div > ul > li:nth-child(3) {
    border-bottom: 1px solid #56aaff;
    border-radius: 0;
  }
  .navbar > nav:has(.menu2) > div > div > ul > li:nth-child(4) {
    border-bottom: 1px solid #56aaff;
    border-radius: 0;
  }
  .navbar > nav:has(.menu2) > div > div > ul > li > a {
    color: #0b9573;
  }

  nav > div > div > ul > li:hover {
    > div {
      transform: none;
      transition: none;
    }
  }

  .link > div:hover {
    > div {
      transform: none;
      transition: none;
    }
  }

  .bars {
    margin-right: 10px;
    display: block;
  }
  .navShow {
    display: flex;
  }
  .navbar.about {
    margin-top: 0px;
    height: auto;
    border: none;
    border-radius: 0;
  }

  .menu,
  ul > li > div > a {
    color: #fff;
  }

  .navbar > nav > div > div > ul > li > div > .active {
    width: auto;
    height: auto;
    border-radius: initial;
    border: none;
    background: none;
    padding: 0;
  }

  .contact {
    color: #2774c1;
    width: 100%;
    height: 43px;
    font-size: 18px;
    background-color: #fff;
  }

  .menu,
  ul > li > div > a {
    font-size: 18px;
  }
}

@media screen and (max-width: 499px) {
  .navbar > nav:has(.menu2) > div > div > ul {
    width: 100%;
    padding: 0 10px;
  }
  .navbar > nav:has(.menu2) > div > div > .menu {
    padding: 0 10px;
    width: 100%;
  }
  .navbar > nav:has(.menu2) > div {
    top: 37px;
  }
}
