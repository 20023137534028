.mainMobileApp {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 120px auto;
}

.subMobileApp {
  max-width: 75%;
  min-width: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
}

.mobiletext {
  background: url(../../assest/Home/MobileAppText.svg) no-repeat;
  width: clamp(300px, 20vw, 650px);
  height: 5vh;
  background-size: contain;
  background-position: center;
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 1vw;
}

.mobiletext h5 {
  font-weight: 600;
  font-size: clamp(20px, 3vw, 20px);
  color: #000;
}

/* .mobileservice {
  width: clamp(280px, 30vw, 500px);
  display: block;
} */

.subMobileApp h3 {
  font-weight: 900;
  /* font-size: clamp(30px, 3vw, 40px); */
  font-size: 2.083vw;
  color: #2774c1;
  font-family: "Roboto", sans-serif;
  font-style: italic;
  margin-top: 1vw;
  width: 70%;
  text-transform: uppercase;
}

.subMobileApp p {
  font-weight: 500;
  font-size: clamp(12px, 2vw, 14px);
  color: #9c9c9c;
  font-family: "Roboto", sans-serif;
  margin-top: 1vw;
}

.submobileservice {
  width: 100%;
  display: flex;
  gap: 20px;
  margin-top: 1.5vw;
}

.submobileservice img {
  width: 3.698vw;
}

.mobilebox123 {
  width: 20vw;
  height: 6.57vh;
  background-color: #2774c1;
  border-radius: 22px;
  border: 1px solid #2774c1;
  display: flex;
  align-items: center;
  padding: 25px;
}

.mobilebox123 h2 {
  font-weight: 500;
  /* font-size: clamp(16px, 2vw, 20px); */
  font-size: 1.042vw;
  color: #fff;
  font-family: "Roboto", sans-serif;
}

.mobileimg {
  width: clamp(300px, 35vw, 648px);
  margin-top: 10%;
}

@media screen and (max-width: 992px) {
  .mainMobileApp {
    width: 100%;
  }

  .mobiletext {
    margin-top: 10px;
    margin-right: 10px;
  }

  .subMobileApp {
    width: 95%;
    flex-direction: column;
    gap: 20px;
  }

  .mobileservice {
    width: 80%;
    display: block;
  }

  .mobileimg {
    width: 100%;
  }

  .subMobileApp h3 {
    font-size: 35px;
    margin-top: 15px;
  }

  .subMobileApp p {
    margin-top: 15px;
  }

  .mobilebox123 h2 {
    font-size: 16px;
  }

  .submobileservice {
    width: 90%;
    margin-top: 25px;
  }

  .mobilebox123 {
    width: 100%;
    height: 71px;
    background-color: #2774c1;
    border-radius: 22px;
    border: 1px solid #2774c1;
    display: flex;
    align-items: center;
    padding: 25px;
  }
}

@media screen and (max-width: 499px) {
  .submobileservice img {
    width: 49px;
  }

  .subMobileApp h3 {
    font-size: 30px;
  }
}
