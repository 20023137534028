.servicemain {
  width: 100%;
  background-color: #f4f4f4;
  /* background: url(../../assest/Home/bgsp.svg) no-repeat; */
  /* background-position: center;
  background-size: cover; */
  padding-top: 100px;
  padding-bottom: 100px;
  /* margin: 150px auto; */
}

.subservice {
  width: 85%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin: auto;
  gap: 2.5vw;
}

.serviceimg {
  width: clamp(303px, 25vw, 555px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 6vw;
  margin: auto;
}

.serviceimg img {
  width: clamp(303px, 25vw, 555px);
  display: flex;
  margin: auto;
}

.servicetext {
  width: 70%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1vw;
  margin: auto;
}

.servicetext12 {
  margin-top: 30px;
  margin-left: 100px;
  margin-right: 100px;
}

.serviceimg h1 {
  font-weight: 700;
  font-size: 1.667vw;
  color: #292929;
  font-family: "Roboto", sans-serif;
  margin-top: 1vw;
}

.serviceimg p {
  font-weight: 500;
  font-size: 1.042vw;
  color: #9c9c9c;
  font-family: "Roboto", sans-serif;
}

.servicetext h1 {
  font-weight: 900;
  font-size: 2.083vw;
  color: #292929;
  font-family: "Roboto", sans-serif;
  text-transform: uppercase;
}

.servicetext p {
  font-weight: 500;
  /* font-size: 1vw; */
  font-size: 0.833vw;
  color: #9c9c9c;
  font-family: "Roboto", sans-serif;
  line-height: 20px;
  text-align: center;
}

.serviceimg h3 {
  width: clamp(303px, 50vw, 555px);
  font-weight: 500;
  font-size: 0.833vw;
  color: #9c9c9c;
  font-family: "Roboto", sans-serif;
  line-height: 22px;
  margin: 1.5vw 0;
}

.serviceimg a {
  font-weight: 500;
  font-size: 1vw;
  color: #56aaff;
  font-family: "Roboto", sans-serif;
  margin: 1.5vw 0;
  cursor: pointer;
}

.imgservicesec {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1.5vw;
}

.even-order {
  order: 1;
}

.odd-order {
  order: -1;
}

@media screen and (max-width: 992px) {
  .servicemain {
    width: 100%;
  }

  .subservice {
    width: 90%;
    gap: 20px;
  }

  .serviceimg {
    width: 100%;
    flex-direction: column;
    gap: 10px;
    margin: auto;
  }

  .servicetext {
    width: 92%;
    gap: 16px;
  }

  .servicetext h1 {
    text-align: start;
    font-size: 40px;
  }

  .servicetext p {
    font-size: 16px;
    text-align: start;
  }

  .serviceimg h3 {
    width: 100%;
    font-size: 16px;
    line-height: 22px;
    margin: 20px 0;
  }

  .serviceimg h1 {
    font-size: 24px;
    margin-top: 120px;
  }

  .serviceimg p {
    font-size: 18px;
  }

  .serviceimg a {
    font-size: 16px;
  }

  .imgservicesec {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
  }
  .imgservicesec {
    display: flex;
    flex-direction: column;
  }

  .servicetext12 {
    margin-top: 0px;
    margin-left: 0px;
    margin-right: 0px;
  }

  .even-order {
    order: 1;
  }

  .odd-order {
    order: 1;
  }
}

@media screen and (max-width: 499px) {
  .serviceimg h1 {
    font-size: 24px;
    margin-top: 20px;
  }
}
