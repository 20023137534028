.formfull {
  width: 100%;
}

.formfull > h5 {
  font-size: 1.25vw;
  font-weight: 600;
  text-transform: capitalize;
  color: #2774c1;
}

form > div > .formic {
  border: none;
  border-bottom: 1px solid #bababa;
  box-sizing: border-box;
  padding: 0.833vw;
}

input {
  border: none;
  outline: none;
  font-size: 1vw;
  /* padding-left: 2vw; */
  width: 100%;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important; /* Override background color */
}

.arrows {
  cursor: pointer;
  transition: transform 0.3s ease; /* Apply transition to the arrow */
}

.arrows > img {
  transform: rotate(360deg);
  position: relative;
  bottom: 1vw;
}

.custom-select {
  width: 100%;
  display: flex;
  height: 2vh;
  /* padding-left: 120px; */
  justify-content: space-between;
}

.arrows.rotated img {
  transform: rotate(180deg);
  width: 100%;
  height: auto;
}

.options {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease-in-out;
  display: flex;
  flex-direction: column;
  gap: 0.75vw;
  justify-content: flex-start;
  align-items: flex-start;
  padding-left: 1vw;
  /* border: 1px solid red; */
}

.show-options {
  max-height: 300px;
}

label {
  color: rgba(0, 0, 0, 0.5);
  font-size: 1vw;
  font-weight: 500;
  font-family: "Roboto", sans-serif;
}

input::placeholder {
  color: rgba(0, 0, 0, 0.2);
  font-size: 10px;
  font-weight: 500;
}

select {
  border: none;
  outline: none;
  width: 100%;
  background: none;
}

.error {
  color: red;
  padding-top: 10px;
}

.optionsegment {
  width: 100%;
  font-weight: 500;
  font-size: 1vw;
  color: #bababa;
  font-family: "Roboto", sans-serif;
  text-align: center;
}

.btn9 {
  width: 7vw;
  height: 4.5vh;
  justify-content: center;
  align-items: center;
  border-radius: 55px;
  background: #2774c1;
  color: #fff;
  font-size: 1vw;
  font-weight: 700;
  text-transform: uppercase;
  outline: none;
  border: none;
  cursor: pointer;
  margin-top: 1vw;
}
.hidden {
  display: none;
}

@media screen and (max-width: 992px) {
  .formfull > h5 {
    width: 85%;
    font-size: 20px;
  }

  .arrows > img {
    bottom: 10px;
  }

  label {
    font-size: 16px;
  }

  .options {
    gap: 10px;
    padding-left: 16px;
  }

  .optionsegment {
    font-size: 16px;
  }

  form > div > .formic {
    padding: 16px;
  }

  .btn9 {
    width: 100%;
    height: 4.5vh;
    font-size: 18px;
    margin-top: 10px;
  }

  input {
    font-size: 18px;
    padding-left: 15px;
  }

  .arrows > img {
    bottom: 10px;
  }

  .custom-select {
    height: auto;
    padding-left: 0px;
  }
}

@media screen and (max-width: 499px) {
  .formfull > h5 {
    width: 90%;
    font-size: 18px;
  }

  .arrows > img {
    bottom: 8px;
  }

  label {
    font-size: 14px;
  }

  .options {
    gap: 8px;
    padding-left: 12px;
  }

  .optionsegment {
    font-size: 12px;
  }

  form > div > .formic {
    padding: 12px;
  }

  .btn9 {
    width: 100%;
    height: 4.5vh;
    font-size: 14px;
    margin-top: 8px;
  }
}
