.revenuemain {
  width: 100%;
  background-color: #e2e2e2;
  /* height: 243px; */
  height: 25vh;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.subrevenue {
  width: 70%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  margin: auto;
  /* padding-top: 40px; */
}

.subtext {
  display: flex;
  gap: 2vw;
}

.subtext h1 {
  font-weight: 900;
  /* font-size: clamp(50px, 5vw, 60px); */
  font-size: 3.125vw;
  color: #292929;
  font-family: "Roboto", sans-serif;
  margin-top: 0.75vw;
}

.subtext p {
  font-weight: 700;
  /* font-size: clamp(32px, 4vw, 32px); */
  font-size: 1.667vw;
  color: #9c9c9c;
  font-family: "Roboto", sans-serif;
}

.vertical-line {
  border-left: 3px solid #292929;
  height: 13vh;
}

.horizontal-line {
  display: none;
}

@media screen and (max-width: 992px) {
  .revenuemain {
    width: 100%;
    height: auto;
    margin: auto;
  }

  .subrevenue {
    width: 80%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    padding-bottom: 60px;
  }
  .vertical-line {
    margin-top: 35px;
    height: 110px;
  }

  .subtext {
    display: flex;
    gap: 15px;
  }

  .subtext h1 {
    margin-top: 30px;
  }

  .horizontal-line {
    display: none;
  }

  .subtext h1 {
    font-size: 55px;
  }

  .subtext p {
    font-size: 30px;
  }
}

@media screen and (max-width: 768px) {
  .subrevenue {
    width: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
    padding: 40px;
  }
  .subtext h1 {
    margin-top: 0px;
  }
  .vertical-line {
    display: none;
  }

  .horizontal-line {
    display: block;
    border-bottom: 3px solid #292929;
    width: 274px;
    margin-top: 20px;
  }
}
