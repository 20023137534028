.privacymain {
  width: 90%;
  margin: 120px auto;
  position: relative;
}

.privacymain h1 {
  font-weight: 900;
  font-size: 3.125vw;
  color: #292929;
  font-family: "Roboto", sans-serif;
  font-style: italic;
  text-align: center;
}

.privacymain p {
  font-weight: 500;
  font-size: 0.938vw;
  color: #9c9c9c;
  font-family: "Roboto", sans-serif;
}

.privacymain h3 {
  font-weight: 500;
  font-size: 1.146vw;
  color: #292929;
  font-family: "Roboto", sans-serif;
  margin: 20px 0;
}

.listprivacy {
  list-style-type: disc;
  color: black;
  font-weight: 500;
  font-size: 0.938vw;
  font-family: "Roboto", sans-serif;
  margin: 15px 0;
}

.privacysub {
  width: 98%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: auto;
}

.privacylist1 {
  list-style-type: disc;
  color: #9c9c9c;
  font-weight: 500;
  font-size: 0.938vw;
  font-family: "Roboto", sans-serif;
  margin: 10px 0;
}

.vl {
  border-left: 1px solid #9c9c9c;
  height: 92%;
  position: absolute;
  top: 10%;
  left: 55%;
}

.informationsection {
  width: 40%;
  margin: 20px 0;
}

.useinformation {
  width: 40%;
  margin: 20px 0;
}

@media screen and (max-width: 992px) {
  .privacymain {
    width: 90%;
    margin: 120px auto;
  }

  .privacysub {
    width: 98%;
    display: flex;
    flex-direction: column;
  }

  .privacymain h1 {
    font-size: 60px;
    text-align: start;
  }

  .privacymain h2 {
    font-size: 40px;
  }

  .privacymain p {
    font-size: 18px;
    margin: 15px auto;
  }

  .privacymain h3 {
    font-size: 22px;
  }

  .listprivacy {
    font-size: 18px;
  }

  .privacylist1 {
    font-size: 18px;
  }

  .vl {
    display: none;
  }

  hr {
    display: none;
  }

  .informationsection {
    width: 90%;
  }

  .useinformation {
    width: 90%;
  }
}
