.dailymain1 {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 0 auto;
}

.servicetext3 {
  background: url(../../assest/Services/Services5.svg) no-repeat;
  width: 15vw;
  height: 4vh;
  background-size: contain;
  background-position: center;
  display: flex;
  align-items: center;
  gap: 10px;
  position: relative;
  top: 9vw;
  right: 40%;
}

.servicetext3 h5 {
  font-weight: 500;
  font-size: 1.042vw;
  color: #000;
}
.serviceDaily1 {
  background: url(../../assest/Services/Service1.svg) no-repeat;
  width: 100%;
  background-position: center;
  background-size: contain;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 7vw 2vw;
  position: relative;
}

.dailytext1 {
  width: 68%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 0.6vw;
  padding-top: 9vw;
}

.dailytext1 h3 {
  font-weight: 900;
  font-size: 2.083vw;
  color: #2774c1;
  font-family: "Roboto", sans-serif;
  text-transform: uppercase;
}

/* .dailytext1 span {
  color: #2774c1;
} */

.dailytext1 h4 {
  font-weight: 800;
  font-size: 1.042vw;
  color: #292929;
}

.dailytext1 h5 {
  width: 92%;
  font-weight: 500;
  font-size: 0.833vw;
  /* font-size: 1.042vw; */
  color: #292929;
  margin-top: 0.2vw;
  line-height: 1.25vw;
}

.serviceimgsection {
  width: 30%;
}

.serviceimgsection img {
  margin-bottom: 8vw;
  width: 100%;
}

.servicebtn {
  position: absolute;
  top: 80%;
  right: 7vw;
}

@media screen and (max-width: 992px) {
  .dailymain1 {
    width: 100%;
    margin-bottom: 50px;
  }

  .dailymain1 {
    width: 100%;
  }
  .serviceDaily1 {
    background: none;
    display: flex;
    flex-direction: column;
    gap: 25px;
  }

  .dailytext1 {
    width: 100%;
    gap: 12px;
    padding-top: 30px;
  }
  .servicetext3 {
    right: 34%;
    width: clamp(260px, 15vw, 280px);
  }

  .servicetext3 h5 {
    font-size: 18px;
  }

  .dailytext1 h3 {
    font-size: 35px;
  }

  .dailytext1 h4 {
    font-size: 18px;
  }

  .dailytext1 h5 {
    width: 100%;
    font-size: 16px;
    line-height: 20px;
  }

  .serviceimgsection {
    width: 100%;
  }

  .serviceimgsection img {
    margin-bottom: 0px;
    width: 100%;
  }

  .servicebtn {
    position: absolute;
    top: 98%;
    right: 70%;
  }
}

@media screen and (max-width: 499px) {
  .dailymain1 {
    width: 100%;
  }
  .servicetext3 h5 {
    font-size: 16px;
  }

  .servicetext3 {
    right: 15%;
  }

  .dailytext1 h3 {
    font-size: 30px;
  }

  .dailytext1 h4 {
    font-size: 16px;
  }

  .dailytext1 h5 {
    font-size: 14px;
  }

  .servicebtn {
    position: absolute;
    top: 98%;
    right: 30%;
  }
}
