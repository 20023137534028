.landingPage {
  width: 100%;
  height: 90vh;
  display: flex;
  flex-direction: column;
  position: relative;
  top: 10px;
  margin: auto auto 10px;
}

.landinimg {
  width: 100%;
}

.landinimg1 {
  display: none;
}

.landtext {
  position: absolute;
  top: 20%;
  left: 12vw;
  display: block;
}

.landtext h1 {
  width: 37vw;
  font-weight: 900;
  font-size: 3.125vw;
  color: #fff;
  font-family: "Roboto", sans-serif;
  text-transform: uppercase;
  margin-bottom: 1.042vw;
  font-style: italic;
}

.landtext p {
  width: 25vw;
  font-weight: 500;
  font-size: 0.729vw;
  /* font-size: clamp(12px, 2vw, 14px); */
  color: #fff;
  font-family: "Roboto", sans-serif;
  /* line-height: 20px; */
  line-height: 1.042vw;
  margin-bottom: 1.042vw;
  /* margin-bottom: 20px; */
}

.landtextGallery {
  position: absolute;
  top: 45%;
  left: 17%;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1vw;
}

.landtextGallery h1 {
  font-weight: 900;
  font-size: 3.125vw;
  font-family: "Roboto", sans-serif;
  color: #fff;
  font-style: italic;
}

.landtextGallery p {
  width: 60%;
  font-weight: 500;
  font-size: 0.729vw;
  color: #fff;
  font-family: "Roboto", sans-serif;
  line-height: 20px;
}

@media screen and (max-width: 1024px) {
  .landingPage {
    width: 100%;
    height: 100%;
    position: relative;
    height: auto;
  }

  .landinimg {
    width: 100%;
  }
  .landinimg1 {
    display: none;
  }

  .landtext {
    position: absolute;
    top: 29.5%;
    left: 10%;
    gap: 10px;
  }

  .landtext h1 {
    width: 50%;
    font-size: 40px;
    margin-bottom: 20px;
  }

  .landtext p {
    width: 70%;
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 20px;
  }

  .landtextGallery {
    position: absolute;
    top: 25%;
    left: 1%;
  }

  .landtextGallery h1 {
    font-size: 40px;
  }

  .landtextGallery p {
    width: 90%;
    font-size: 14px;
  }
}

@media screen and (max-width: 768px) {
  .landingPage {
    width: 100%;
    position: relative;
  }

  .landtext {
    position: absolute;
    top: 20%;
    left: 20px;
    padding: 2%;
  }

  .landinimg {
    display: none;
  }

  .landinimg1 {
    display: block;
    width: 100%;
  }

  .landtext h1 {
    width: 50%;
    font-size: 30px;
  }

  .landtext p {
    width: 75%;
    font-size: 12px;

    /* height: 80px; */
  }

  .landtextGallery {
    position: absolute;
    height: 100%;
    top: 35%;
    left: 0;
    gap: 30px;
  }

  .landtextGallery h1 {
    font-size: 70px;
  }

  .landtextGallery p {
    width: 70%;
    font-size: 20px;
  }
}

@media screen and (max-width: 499px) {
  .landtextGallery {
    position: absolute;
    height: 100%;
    top: 35%;
    left: 0;
    gap: 30px;
  }

  .landtextGallery h1 {
    font-size: 50px;
  }

  .landtextGallery p {
    font-size: 16px;
  }
}
