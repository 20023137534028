.carclean {
  width: 100%;
  margin: 100px auto;
}

.subcarclean {
  width: 80%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 2vw;
  margin: 100px auto;
}

.carCleanText {
  display: flex;
  flex-direction: column;
  gap: 1vw;
}

.carCleanText h1 {
  font-weight: 900;
  font-size: 2.083vw;
  color: #2774c1;
  text-align: center;
  font-style: italic;
  text-transform: uppercase;
}

.carCleanText p {
  font-weight: 500;
  font-size: 0.833vw;
  color: #9c9c9c;
  line-height: 1.302vw;
  text-align: center;
}

.carCleanCard {
  /* display: grid;
  grid-template-columns: repeat(3, 1fr); */
  display: flex;
  flex-wrap: wrap;
  /* align-items: center; */
  justify-content: center;
  gap: 25px;
  margin: auto;
}

.cardClean {
  width: 23vw;
  height: fit-content;
  border-radius: 42px;
  background-color: #fff;
  border: 1px solid #0984ff;
  box-shadow: 4px 4px 44px 15px #1195df0f;
  transition: box-shadow 0.3s ease, transform 0.3s ease;
  display: flex;
  flex-direction: column;
  gap: 0.75vw;
  padding: 1.4vw;
}

.subcard1 img {
  width: 3.021vw;
}

.cardClean h2 {
  font-weight: 700;
  font-size: 1.25vw;
  color: #2774c1;
  font-style: italic;
  margin-top: 0.5vw;
}

.cardClean p {
  font-weight: 500;
  font-size: 0.833vw;
  color: #9c9c9c;
  line-height: 1.3vw;
}

.cardClean span {
  font-weight: 500;
  font-size: 0.833vw;
  color: #2774c1;
  text-decoration: underline;
}

.subcard1 {
  display: flex;
  gap: 0.75vw;
}

@media screen and (max-width: 992px) {
  .carclean {
    width: 100%;
    margin: 60px auto;
  }

  .subcarclean {
    width: 90%;
    gap: 20px;
    margin: 60px auto;
  }

  .carCleanText {
    gap: 16px;
  }

  .carCleanText h1 {
    font-size: 30px;
  }

  .carCleanText p {
    font-size: 16px;
    line-height: 20px;
  }

  .carCleanCard {
    display: flex;
    flex-wrap: wrap;
    gap: 25px;
  }

  .cardClean {
    width: 350px;
    gap: 10px;
    padding: 12px;
  }

  .subcard1 img {
    width: 45px;
  }

  .cardClean h2 {
    font-size: 22px;
    margin-top: 10px;
  }

  .cardClean p {
    font-size: 14px;
    line-height: 22px;
  }

  .cardClean span {
    font-size: 14px;
    margin-left: 12px;
  }

  .subcard1 {
    display: flex;
    gap: 10px;
  }
}

@media screen and (max-width: 820px) {
  .cardClean {
    width: 100%;
    gap: 12px;
    padding: 20px;
  }
}
