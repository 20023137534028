.ourServicemain {
  background: linear-gradient(97.46deg, #231f20 7.39%, #0984ff 137.57%);
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.subOurService {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 2vw;
  justify-content: center;
  margin: 120px auto;
}

.subOurService h1 {
  width: 45%;
  font-weight: 900;
  font-size: 2.083vw;
  color: #fff;
  font-family: "Roboto", sans-serif;
  text-align: center;
  text-transform: uppercase;
  font-style: italic;
}

/* .subOurService span {
  color: #56aaff;
} */

.subOurService h2 {
  width: 60%;
  font-weight: 500;
  /* font-size: clamp(12px, 2vw, 16px); */
  font-size: 0.833vw;
  color: #bababa;
  font-family: "Roboto", sans-serif;
  text-align: center;
}

.servicetexts {
  background: url(../../assest/Home/ServicesText.svg) no-repeat;
  width: clamp(250px, 10vw, 300px);
  height: 5vh;
  background-size: contain;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.servicetexts h5 {
  font-weight: 600;
  /* font-size: clamp(20px, 2vw, 20px); */
  font-size: 1.042vw;
  color: #fff;
  font-family: "Roboto", sans-serif;
}

.servicebox {
  display: flex;
  gap: 1.5vw;
}

.ourServicebox {
  width: 15vw;
  border-radius: 2.865vw;
  height: auto;
  border: 1px solid #0984ff;
  display: flex;
  flex-direction: column;
  gap: 2vw;
  outline: none;
  background-color: #fff;
  transition: all 0.3s ease;
  padding-bottom: 0.75vw;
}

.ourServicebox img {
  width: 15vw;
}

.servicetext2 {
  width: 90%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 0.8vw;
}

.servicetext2 p {
  font-weight: 400;
  font-size: 0.625vw;
  color: #9c9c9c;
  font-family: "Roboto", sans-serif;
  line-height: 14.06px;
}

.servicetext2 h3 {
  font-weight: 500;
  font-size: 1.042vw;
  color: #000;
  font-family: "Roboto", sans-serif;
}

.cross {
  transform: rotate(-3.91deg);
}

@media screen and (max-width: 850px) {
  .ourServicemain {
    width: 100%;
  }

  .subOurService {
    gap: 20px;
  }

  .subOurService h1 {
    width: 80%;
    font-size: clamp(30px, 2vw, 40px);
  }

  .subOurService h2 {
    width: 80%;
    font-size: clamp(14px, 2vw, 16px);
  }

  .servicebox {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }

  .servicetext2 {
    gap: 16px;
  }

  .cross {
    transform: none;
  }

  .servicetext2 h3 {
    font-size: clamp(20px, 3vw, 20px);
  }

  .servicetext2 p {
    font-size: clamp(12px, 1vw, 12px);
  }

  .ourServicebox img {
    width: clamp(260px, 15vw, 283px);
  }

  .ourServicebox {
    width: clamp(260px, 15vw, 283px);
    height: 455px;
    border-radius: 55px;
    gap: 20px;
    padding-bottom: 12px;
  }

  .servicetexts h5 {
    font-size: clamp(20px, 2vw, 20px);
  }
}
