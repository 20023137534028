.downloadmobile {
  background: linear-gradient(105.43deg, #f2f9ff 39.18%, #c9e4ff 93.15%);
  width: 100%;
  height: 75vh;
  border-radius: 55px;
  display: flex;
  align-items: center;
  margin: auto;
  padding: 10px;
}

.subdownloadmobile {
  width: 90%;
  height: auto;
  display: flex;
  align-items: center;
  /* justify-content: space-around; */
  margin: auto;
}

.downloadmobiletext {
  width: clamp(290px, 25vw, 463px);
  display: flex;
  flex-direction: column;
  gap: 1vw;
  justify-content: center;
  margin: auto;
}

.downloadmobiletext h1 {
  font-weight: 900;
  font-size: 2.083vw;
  color: #000000;
  font-style: italic;
  width: 80%;
}

.downloadmobiletext p {
  font-weight: 500;
  font-size: 0.833vw;
  color: #9c9c9c;
  line-height: 1.25vw;
}

.downloadmobiletext h4 {
  font-weight: 500;
  font-size: 1.042vw;
  color: #2774c1;
  font-style: italic;
}

.downloadmobiletext h3 {
  font-weight: 700;
  font-size: 2.083vw;
  color: #2774c1;
  font-style: italic;
}

.store12 {
  display: flex;
  flex-direction: column;
  gap: 0.3vw;
}

.store12 img {
  width: 8.646vw;
}

.downloadmobileimg {
  width: clamp(290px, 25vw, 467px);
}

@media screen and (max-width: 992px) {
  .downloadmobile {
    width: 100%;
    border-radius: 34px;
    height: auto;
  }

  .subdownloadmobile {
    width: 90%;
    flex-direction: column-reverse;
  }

  .downloadmobiletext {
    width: 100%;
    gap: 8px;
  }

  .downloadmobiletext h1 {
    font-size: 35px;
  }

  .downloadmobiletext p {
    font-size: 15px;
    line-height: 24px;
  }

  .downloadmobiletext h4 {
    font-size: 18px;
  }

  .downloadmobiletext h3 {
    font-size: 35px;
  }

  .store12 {
    flex-direction: row;
    gap: 6px;
  }

  .store12 img {
    width: 90%;
  }

  .downloadmobileimg {
    width: 90%;
    display: flex;
    justify-content: center;
    margin: auto;
  }
}

@media screen and (max-width: 499px) {
  .downloadmobile {
    width: 100%;
    border-radius: 34px;
  }

  .downloadmobiletext {
    width: 100%;
    gap: 12px;
  }

  .downloadmobiletext h1 {
    font-size: 30px;
  }

  .downloadmobiletext p {
    font-size: 12px;
  }

  .downloadmobiletext h4 {
    font-size: 15px;
  }

  .downloadmobiletext h3 {
    font-size: 30px;
  }
}
