.brandcompy {
  background-color: #f1f1f1;
  width: 100%;
  height: 30vh;
  display: flex;
  margin: auto;
}

.Marquee {
  width: 100%;
  color: white;
  font-weight: 200;
  display: flex;
  align-items: center;
  overflow: hidden;
  margin: auto;
  mix-blend-mode: luminosity;
}

.Marquee-content {
  display: flex;
  gap: 15px;
  animation: marquee 12s linear infinite running;
}

.Marquee-tag {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto 20px;
  flex-direction: column;
  transition: all 0.1s ease;
  cursor: pointer;
}

.Marquee-tag p {
  font-weight: 600;
  font-size: 16px;
  color: #9c9c9c;
}

.Marquee-tag .marqueeimg {
  width: 174px;
  opacity: 0.5;
  mix-blend-mode: luminosity;
}

@keyframes marquee {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(-50%);
  }
}
