.btn1 {
  /* width: 167px; */
  width: 8.698vw;
  /* height: 57px; */
  height: 5.2778vh;
  /* border-radius: 55px; */
  border-radius: 2.865vw;
  border: 1px solid #2774c1;
  cursor: pointer;
  color: #2774c1;
  font-weight: 900;
  /* font-size: clamp(16px, 10vw, 16px); */
  font-size: 0.833vw;
  font-family: "Roboto", sans-serif;
}

.btn2 {
  /* width: 224px;
  height: 64px; */
  width: 15.104vw;
  height: 7.6852vh;
  border-radius: 2.865vw;
  border: 1px solid #2774c1;
  cursor: pointer;
  color: #fff;
  font-weight: 900;
  font-size: 1.146vw;
  /* font-size: clamp(20px, 10vw, 22px); */
  font-family: "Roboto", sans-serif;
  background-color: #2774c1;
}

.btn7 {
  width: 8.698vw;
  height: 5.2778vh;
  /* height: 57px; */
  border-radius: 2.865vw;
  border: 1px solid #2774c1;
  cursor: pointer;
  color: #fff;
  font-weight: 900;
  /* font-size: 20px; */
  font-size: 0.833vw;
  font-family: "Roboto", sans-serif;
  background-color: #2774c1;
}

.btn2:hover {
  background-color: white;
  color: #2774c1;
  transition: all 0.3s linear;
}

.btn1:hover {
  background-color: transparent;
  color: white;
  border: 1px solid #fff;
  transition: all 0.3s linear;
}

@media screen and (max-width: 992px) {
  .btn1 {
    width: 167px;
    height: 50px;
    font-size: 16px;
    border-radius: 55px;
  }

  .btn2 {
    width: 224px;
    height: 60px;
    font-size: 16px;
    border-radius: 55px;
  }
}

@media screen and (max-width: 850px) {
  .btn7 {
    width: 167px;
    height: 50px;
    font-size: 16px;
    border-radius: 55px;
  }
}
