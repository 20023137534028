.circletitle {
  width: 60%;
  margin: auto;
}

.circletitle h1 {
  font-weight: 900;
  font-size: 1.563vw;
  color: #292929;
  text-transform: uppercase;
  font-style: italic;
}

.containermain {
  width: 65%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 10.417vw;
  margin: auto auto 100px;
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
}

.circle {
  width: 116px;
}

.circle circle {
  fill: transparent;
  stroke: url(#gradient);
  stroke-width: 3px;
  stroke-dasharray: 251.2; /* Circumference of r=40 circle */
  stroke-dashoffset: 0;
  transition: transform 0.8s ease;
  transform-origin: center;
}

.container:hover .circle circle {
  transform: rotate(-90deg); /* Rotate by -90 degrees on hover */
}

.gradient {
  stroke: linear-gradient(136.71deg, #0984ff -9.38%, #ffffff 84.56%);
}

.circle-text {
  width: 280px;
  font-weight: 800;
  /* font-size: clamp(20px, 10vw, 20px); */
  font-size: 1.042vw;
  color: #292929;
  font-family: "Roboto", sans-serif;
  transition: color 0.3s ease;
  text-transform: uppercase;
}

.container:hover .circle-text {
  color: #0984ff;
}

.circle-text:hover {
  color: #0984ff;
}

@media screen and (max-width: 992px) {
  .circle-text {
    width: 100%;
    font-size: 20px;
  }

  .containermain {
    width: 90%;
    display: flex;
    gap: 15px;
    flex-wrap: wrap;
    align-items: center;
  }

  .circletitle {
    width: 90%;
    margin: 20px auto;
  }

  .circletitle h1 {
    font-size: 30px;
  }
}
