.testimonialmain {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: 120px auto;
  width: 85%;
}

.testimonialtxt {
  width: 30%;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.testimonialtxt h4 {
  font-weight: 900;
  font-size: 2.1vw;
  color: #2774c1;
  font-family: "Roboto", sans-serif;
  text-transform: uppercase;
  font-style: italic;
}

/* .testimonialtxt span {
  color: #2774c1;
} */

.testimonialtxt h6 {
  font-weight: 500;
  font-size: 0.833vw;
  color: #9c9c9c;
  line-height: 24px;
}

.testimonialtext1 {
  background: url(../../assest/Home/TestimonialText5.svg) no-repeat;
  width: clamp(280px, 10vw, 440px);
  height: 5vh;
  background-size: contain;
  background-position: center;
  display: flex;
  align-items: center;
  gap: 10px;
}

.testimonialtext1 h5 {
  font-weight: 600;
  font-size: 1.25vw;
  color: #000;
  font-family: "Roboto", sans-serif;
}

.card {
  width: 50%;
  padding: 10px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 4.0625rem 0rem 0rem 0rem;
}

.card .spl {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.card .spl .carousel {
  width: 441px;
  height: 419px;
  border: 1px solid #0984ff;
  border-radius: 20px;
  background: #fff;
  box-shadow: 4px 4px 44px 0px #0984ff33;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: auto;
}

.card .spl .carousel .carosalsliding {
  padding: 3vw;
}

.card .spl .carousel .carosalsliding .carousels {
  display: flex;
  gap: 15px;
  position: relative;
  bottom: 30px;
}

.circleimg4 {
  width: 4.2vw;
  /* height: 7.4074vh; */
  /* height: 104px; */
}

.imgstar {
  width: 96px;
  margin-right: 100px;
}

.card .spl .carousel .carosalsliding .carousels h3 {
  color: #000;
  font-family: "Roboto", sans-serif;
  /* font-size: 2.1875rem; */
  font-size: clamp(16px, 5vw, 20px);
  font-style: normal;
  font-weight: 700;
  line-height: 158.3%; /* 3.46281rem */
  text-transform: capitalize;
}

.card .spl .carousel .carosalsliding .carousels span {
  color: rgba(0, 0, 0, 0.3);
  font-family: "Roboto", sans-serif;
  /* font-size: 1.375rem; */
  /* font-size: 1.146vw; */
  font-size: clamp(14px, 5vw, 16px);
  font-style: normal;
  font-weight: 400;
  line-height: 158.3%;
  text-transform: capitalize;
}

.card .spl .carousel .carosalsliding p {
  color: rgba(0, 0, 0, 0.54);
  font-family: "Roboto", sans-serif;
  /* font-size: 1.125rem; */
  font-size: clamp(14px, 5vw, 16px);

  /* font-size: 0.938vw; */
  font-style: normal;
  font-weight: 400;
  line-height: 155.3%; /* 1.74713rem */
}

.splide__pagination {
  text-align: center;
  position: relative !important;
  top: 40px !important;
}

.splide__pagination__page {
  width: 0.75vw !important;
  height: 1.5741vh !important;
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 50%;
  margin: 0 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.splide__pagination__page.is-active {
  background-color: rgba(0, 0, 0, 0.8) !important;
}

@media screen and (max-width: 992px) {
  .testimonialmain {
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 20px;
    align-items: center;
    width: 100%;
  }

  .card {
    width: 100%;
  }

  .card .spl .carousel {
    width: 90%;
  }

  .testimonialtxt {
    width: 90%;
  }

  .testimonialtxt h4 {
    font-size: 26px;
  }

  .testimonialtext1 h5 {
    font-size: 24px;
  }

  .testimonialtxt h6 {
    font-size: 16px;
  }

  .splide__pagination__page {
    width: 15px !important;
    height: 15px !important;
  }

  .circleimg4 {
    width: 80px;
    height: 80px;
  }
}
