.contactinfo {
  background-color: #56aaff;
  border-radius: 1.5vw;
  width: 35%;
  height: 48vh;
  display: flex;
  flex-direction: column;
  gap: 1vw;
  padding: 0.9vw;
}

.contactinfo h2 {
  font-weight: 600;
  font-size: 1vw;
  color: #fff;
  font-family: "Roboto", sans-serif;
}

.subcontact1 {
  display: flex;
  gap: 1vw;
}

.subcontact1 img {
  width: 1.4vw;
}

.subcontact1 h4 {
  font-weight: 600;
  font-size: 1vw;
  color: #fff;
  font-family: "Roboto", sans-serif;
}

.location {
  position: relative;
  border-radius: 1vw;
  overflow: hidden;
}

.location > h3 {
  width: 100%;
  position: absolute;
  bottom: 0;
  font-size: 1vw;
  font-weight: 400;
  background: #a0cfff;
  padding: 0.7vw;
  color: #fff;
}

.mapstyle {
  width: 100%;
  height: 30vh;
}

@media screen and (max-width: 992px) {
  .contactinfo {
    background-color: #56aaff;
    border-radius: 24px;
    width: 100%;
    height: auto;
    display: flex;
    gap: 16px;
    padding: 16px;
  }

  .location {
    border-radius: 16px;
  }

  .contactinfo h2 {
    font-size: 20px;
  }

  .subcontact1 h4 {
    font-size: 16px;
  }

  .subcontact1 {
    gap: 16px;
  }

  .subcontact1 img {
    width: 16px;
  }

  .mapstyle {
    height: auto;
  }
}

@media screen and (max-width: 499px) {
  .contactinfo {
    border-radius: 20px;
    gap: 10px;
    padding: 12px;
  }

  .location {
    border-radius: 12px;
  }

  .contactinfo h2 {
    font-size: 18px;
  }

  .subcontact1 h4 {
    font-size: 14px;
  }

  .subcontact1 {
    gap: 12px;
  }

  .subcontact1 img {
    width: 13px;
  }
}
